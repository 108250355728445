<template>
  <div>
    <div class="form-group">
      <label for="">Nombre*</label>
      <app-input-string v-model="name"></app-input-string>
    </div>
    <div class="form-group">
      <app-switch v-model="enable">Activo</app-switch>
    </div>
    <div class="form-group">
      <app-button-submit @click="save()"></app-button-submit>
    </div>
  </div>
</template>

<script>
// import SelectSource from "../sources/Select";
import { AdminService } from "../AdminService";

export default {
  components: {
    // SelectSource
  },
  data() {
    return {
      id: 0,
      name: "",
      enable: false
    };
  },
  methods: {
    loadReg(item) {
      this.id = item.id;
      this.name = item.name;
      this.enable = item.enable;
    },
    save() {
      AdminService.saveArea(this.$data).then(() => {
        this.$emit("submitted");
      });
    },
    reset() {
      this.id = 0;
      this.name = null;
      this.enable = null;
    }
  }
};
</script>

<style>
</style>
